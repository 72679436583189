<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码" clearable></el-input>
      <el-button type="primary" @click="search" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">异常登记</el-button>
      <el-button type="primary" @click="importRegister = true">批量登记</el-button>
    </el-row>

    <el-table :data="issueList" border stripe>
      <el-table-column align="center" prop="assetSn" label="资产码" width="200"></el-table-column>
      <el-table-column align="center" prop="stationName" label="工站" width="120"></el-table-column>
      <el-table-column align="center" prop="issueRemark" label="异常描述" min-width="400"></el-table-column>
      <el-table-column align="center" prop="finish" label="是否完成" width="80" :formatter="isFormatter"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="80"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="150"></el-table-column>
      <el-table-column align="center" prop="dealUserName" label="处理人" width="80"></el-table-column>
      <el-table-column align="center" prop="dealTime" label="处理时间" width="150"></el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>


    <el-dialog title="作业异常登记" :visible.sync="dialogVisible" :close-on-click-modal="false" width="750px" :before-close="handleClose">
      <el-form v-model="formData" label-width="150px">
        <el-form-item label="资产码：">
          <el-input v-model.trim="formData.assetSn" placeholder="请输入资产码" style="width: 400px" clearable></el-input>
        </el-form-item>
        <el-form-item label="工站：">
          <el-select v-model="formData.station" placeholder="请选择工站" filterable style="width: 400px">
            <el-option value="appearance" label="外观检测工站"></el-option>
            <el-option value="electric" label="电性能检测工站"></el-option>
            <el-option value="qcAppearance" label="品质分析(外观)工站"></el-option>
            <el-option value="qc" label="品质分析工站"></el-option>
            <el-option value="appearanceReview" label="不良复检工站"></el-option>
            <el-option value="advanced" label="高级检验工站"></el-option>
            <el-option value="dataErasure" label="资料擦除工站"></el-option>
            <el-option value="20" label="检验确认工站"></el-option>
            <el-option value="pack" label="包装工站"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="异常描述：">
          <el-input v-model="formData.issueRemark" placeholder="请输入异常描述" type="textarea" maxlength="500" rows="10" style="width: 400px" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addIssue" :loading="loadingAdd">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="批量登记规则" :visible.sync="importRegister" :close-on-click-modal="false" width="800px" :before-close="importHandleClose">
      <ImportComponent template-url="template/busItemIssue.xlsx" upload-url="busItemIssue/upload"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importHandleClose">关闭</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import ImportComponent from "@/components/ImportComponent";

export default {
  name: "MyIssue",
  components: {ImportComponent},
  data() {
    return {
      queryInfo: {
        assetSn: '',
      },
      formData: {
        assetSn: '',
        station: '',
        issueRemark: ''
      },
      total: 0,
      pageSize: 10,
      dialogVisible: false,
      importRegister: false,
      loading: false,
      loadingAdd: false,
      issueList: []
    }
  },
  created() {
    this.search()
  },
  methods: {
    search() {
      this.loading = true
      this.$axios.post('busItemIssue/queryMyIssuePage', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.issueList = res.data.records;
        this.pageSize = res.data.size
        this.total = res.data.total
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });

    },
    addIssue() {
      if (!this.formData.assetSn) {
        return this.$message.error('请输入资产码')
      }
      if (!this.formData.station) {
        return this.$message.error('请选择工站')
      }
      if (!this.formData.issueRemark) {
        return this.$message.error('请输入异常描述')
      }

      this.loadingAdd = true
      this.$axios.post('busItemIssue/addIssue', this.formData).then(response => {
        this.loadingAdd = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.$message.success('作业异常登记成功')

        this.formData.assetSn = ''
        this.formData.station = ''
        this.formData.issueRemark = ''
        this.currentChange(1)
        this.handleClose()
      }, error => {
        this.loadingAdd = false;
        return this.$message.error('查询失败，' + error.message);
      });

    },
    handleClose() {
      this.dialogVisible = false;
    },
    importHandleClose() {
      this.search();
      this.importRegister = false;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>